import CodeSnippet from '../CodeSnippet';

const content = (
  <div>
    <h2>Asynchrony</h2>
    <p>
     When the satellite is offline the cryptosat service caches transmission for
     non-interactive requests. Non-interactive requests are requests that do
     not depend on inputs from the end user but rather only on values generated
     unilaterally by the cryptosat. For such requests the Cryptosat API returns
     the most recent value generated from the cryposat and received via one of
     the ground stations. Responses typically contain a timestamp to indicate
     the exact time the value was generated by the cryptosat. 
    </p>
    <p>
      Interactive requests are queued and sent to the satellite when it is
      online. The Cryptosat API returns a request object which can be used to
      query the request status. For example, when issuing a signing
      request:
    </p>
    <CodeSnippet code={`request = cryptosat.sign('hello, world!');`} />
    <p>
      The request object can be queried in the following manner to determine the
      status of the request:
    </p>
    <CodeSnippet code={`request.status();`} />
    <p>
      The status can be one of <i>Pending</i>, <i>Sent</i> and <i>Ready</i>.
      A <i>Pending</i> status indicates the request is waiting for the cryptosat
      to come online to transmit the request. A <i>Sent</i> status indicates the
      request has been transmitted and acknowledged by the cryptosat.
      A <i>Ready</i> status indicates the request has been processed and the
      result is ready to be consumed by the user. To obtain the result of the
      operation issue the following call:
    </p>
    <CodeSnippet code={`request.result();`} />
    <p>
      The result will return null until the status of the request
      is <i>Ready</i>.
    </p>

  </div>
);

export default content;
